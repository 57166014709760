import React, { Component } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image"

class SobreIberica extends Component {
    render() {
        return(
            <div className="row" style={{margin: '0', padding: '0', width: '100%', backgroundColor: '#D3D3D3'}}>
                <div className="col-sm-12">
                    <div className="container" style={{paddingTop: '5%', paddingBottom: "5%", textAlign: "justify"}}>
                        <h2 style={{textAlign: 'center'}}>Quem Somos</h2>
                        <p>Em 1995 a Ibérica Condutores Elétricos é fundada, fabricando fios e cabos elétricos, rabichos e extensões e assim inicia-se uma jornada que se mantém até hoje.</p>
                        <p>Nossa evolução é constante, pois investimos na qualificação de nossos colaboradores e no que há de mais moderno em maquinário e tecnologia. Nossa determinação em superar todas as expectativas é o que torna tudo isto possível.</p>
                        <p>Hoje, atuamos em todo território nacional oferecendo a melhor solução em condutores elétricos para a demanda de diversos setores.</p>
                        <p>Nossos produtos podem ser encontrados nos principais Player’s do mercado brasileiro e nosso portfólio conta com mais de 260 itens, todos devidamente certificados pelo INMETRO.</p>
                        <p><b>Conte com a Ibérica e surpreenda-se!</b></p>
                    </div>
                </div>
            </div>
        )
    }
}

const QuerySobreNos = () => (
    <StaticQuery
      query={graphql`
      query SobreIbericaQuery {
        contentfulAsset(title: {eq: "sobre-nos"}) {
            gatsbyImageData(quality: 100, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
        }
    `}
      render={(data) => (
        <SobreIberica data={data} />
      )}
    />
  )

export default QuerySobreNos