import React from 'react';
import {Helmet} from "react-helmet";
import Header from "../components/Header"
import "../styles.scss";
import PorQueIberica from '../components/Home/PorQueIberica';
import QualidadeMundial from "../components/Home/QualidadeMundial";
import Contato from "../components/Home/Contato";
import Footer from "../components/Footer";
import {graphql} from 'gatsby';
import HeroVideo from '../components/GeneralComponents/HeroVideo';
import SobreIberica from '../components/Sobre/SobreIberica';

const Empresa = ({data}) => {
    return (
        <>
            <Helmet>
                <html lang="pt-BR" amp/>
                <meta charSet="UTF-8"/>
                <title>Cia. Ibérica - Sobre a Empresa</title>
                <link rel="canonical" href="https://www.ciaiberica.com.br/empresa/"/>
                <meta name="robots" content="index,follow,max-snippet: -1,max-image-preview:large"/>
                <meta property="og:locale" content="pt-BR"/>
                <meta property="og:title" content="Cia. Ibérica - Sobre a Empresa"/>
                <meta property="og:description"
                      content="Confira a história da Cia. Ibérica, empresa brasileira e com tradição no mercado."/>
                <meta property="og:url" content="https://www.ciaiberica.com.br/empresa/"/>
                <meta property="og:sitename" content="Cia. Ibérica"/>
                <meta property="article:publisher" content="https://facebook.com/ibericacondutoreseletricosltda"/>
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
            </Helmet>
            <Header/>
            <HeroVideo props={data}/>
            <SobreIberica/>
            <PorQueIberica/>

            <QualidadeMundial/>
            <Contato/>
            <Footer/>
        </>
    )
}

export const query = graphql`
    query EmpresaQuery {
        video: contentfulAsset(title: {eq: "empresa"}) {
            file {
              url
              contentType
            }
        }
        poster: contentfulAsset(title: {eq: "empresa-poster"}) {
            file {
              url
              contentType
            }
        }
    }
`

export default Empresa